<template>
  <CheckSpecialGoodsMobile v-if="isMobileAgent()" :item="item" @cancel="cancel" @refreshData="refreshData" />
  <CheckSpecialGoodsDesktop v-else :item="item" @cancel="cancel" @refreshData="refreshData" />
</template>

<script>

export default {
  name: "CheckSpecialGoods",
  components: {
    CheckSpecialGoodsMobile: () => import('@/components/pos_goods/CheckSpecialGoodsMobile'),
    CheckSpecialGoodsDesktop: () => import('@/components/pos_goods/CheckSpecialGoodsDesktop'),
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    refreshData(type) {
      this.$emit('refreshData', type)
    },
  }
}
</script>

<style scoped>

</style>
